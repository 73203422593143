<i18n locale="en" lang="yaml">
province.label: "Provice"
province.placeholder: "Select Province"
district.label: "District"
district.placeholder: "Select District"
subdistrict.label: "Sub-District"
subdistrict.placeholder: "Select Sub-District"
postal_code.label: "Postal Code"
postal_code.placeholder: "Please input postal code"
</i18n>
<i18n locale="th" lang="yaml" >
province.label: "จังหวัด"
province.placeholder: "เลือกจังหวัด"
district.label: "อำเภอ/เขต"
district.placeholder: "เลือกอำเภอ/เขต"
subdistrict.label: "ตำบล/แขวง"
subdistrict.placeholder: "เลือกตำบล/แขวง"
postal_code.label: "รหัสไปรษณีย์"
postal_code.placeholder: "ระบุรหัสไปรษณีย์"
</i18n>

<template>
	<div class="province-select-container">
		<div :class="[provincePaneCssClasses,{ 'has-error': hasErrorProvince }]">
			<label>{{$t('province.label')}}</label>
			<a-select
				v-model="selectData.province"
				show-search
				:placeholder="$t('province.placeholder')"
				on-filter-prop="children"
				:filter-option="filterOption"
				:allow-clear="true"
				:get-popup-container="getPopupContainer"
				@change="handleProvinceChange">
				<a-spin v-if="loadProvince" slot="notFoundContent" />
				<a-select-option
					v-for="provinceData in provinces"
					:key="provinceData.id"
					:value="provinceData.name">
					{{provinceData.name}}
				</a-select-option>
			</a-select>
		</div>

		<div :class="[paneCssClasses,{ 'has-error': hasErrorDistrict }]">
			<label>{{$t('district.label')}}</label>
			<a-select
				v-model="selectData.district"
				show-search
				:placeholder="$t('district.placeholder')"
				on-filter-prop="children"
				:filter-option="filterOption"
				:allow-clear="true"
				:disabled="districts.length == 0"
				:get-popup-container="getPopupContainer"
				@change="handleDistrictChange">
				<a-select-option
					v-for="districtData in districts"
					:key="districtData.id"
					:value="districtData.name">
					{{districtData.name}}
				</a-select-option>
			</a-select>
		</div>

		<div :class="[paneCssClasses,{ 'has-error': hasErrorSubdistrict }]">
			<label>{{$t('subdistrict.label')}}</label>
			<a-select
				v-model="selectData.subdistrict"
				show-search
				:placeholder="$t('subdistrict.placeholder')"
				on-filter-prop="children"
				:allow-clear="true"
				:filter-option="filterOption"
				:disabled="subdistricts.length == 0"
				:get-popup-container="getPopupContainer"
				@change="handleSubdistrictChange">
				<a-select-option
					v-for="subdistrictData in subdistricts"
					:key="subdistrictData.id"
					:value="subdistrictData.name">
					{{subdistrictData.name}}
				</a-select-option>
			</a-select>
		</div>

		<div v-if="showPostalCode" :class="[postalCodeCssClasses,'postal-code-pane',{ 'has-error': hasErrorPostalCode }]">
			<label>{{$t('postal_code.label')}}</label>
			<a-input v-model="selectData.postal_code"
				:placeholder="$t('postal_code.placeholder')"
				:max-length="5"
				@change="handlePostalChange"/>
		</div>

		<div :class="{ 'has-error': errorMessages !== null }">
			<div class="ant-form-explain">
				{{errorMessages}}
			</div>
		</div>
	</div>

</template>

<script>
import Vue from "vue"
import debounce from "lodash/debounce"
import {Select} from "ant-design-vue"
import { mapGetters, mapActions, mapState } from 'vuex'
import {isStringEmpty} from "@utils/stringUtil.js"
import {equalsObject} from "@utils/objectUtil.js"
import axios from "axios"
import PopupMixin from "@mixins/PopupMixin.vue"


export default {
	components : {
		"a-select" : Select ,
		"a-select-option" : Select.Option ,
	} ,
	mixins : [PopupMixin] ,
	props: {
		value : {
			type : Object,
			required: true,
		} ,
		require : {
			type: Boolean ,
			default : false
		} ,
		requireProvince : {
			type: Boolean ,
			default: false
		} ,
		showPostalCode : {
			type: Boolean,
			default : false
		} ,
		requirePostalCode : {
			type: Boolean,
			default : false,
		}
	} ,
	data() {
		return {
			loadProvince : false,
			loadDistrict : false,
			loadSubdistrict: false,
			districts : [] ,
			subdistricts : [] ,
			selectData : {
				province : undefined,
				district : undefined,
				subdistrict : undefined,
			} ,
			hasErrorProvince : false,
			hasErrorDistrict : false,
			hasErrorSubdistrict : false,
			hasErrorPostalCode : false,
		}
	} ,
	computed : {
		...mapState('data',['provinces']) ,
		...mapGetters('data',['hasProvinces']) ,
		errorMessages() {
			let field = '';
			if (this.hasErrorProvince)
				field += this.$t('province.label')+', '
			if (this.hasErrorDistrict)
				field += this.$t('district.label')+', '
			if (this.hasErrorSubdistrict)
				field += this.$t('subdistrict.label')+', '
			if (this.hasErrorPostalCode)
				field += this.$t('postal_code.label')+', '

			if (isStringEmpty(field)) {
				return null
			} else {
				field = field.substring(0,field.length-2)
				return this.$t('validate.required',{field : field})
			}
		} ,
		paneCssClasses() {
			return {
				'province-block' : true,
				'required' : this.require
			}
		} ,
		postalCodeCssClasses() {
			return {
				'province-block' : true,
				'required' : this.require || this.requirePostalCode
			}
		} ,
		provincePaneCssClasses() {
			return {
				'province-block' : true,
				'required' : this.require || this.requireProvince
			}
		}
	},
	watch : {
		value : {
			handler(newAddress,oldAddress) {
				if (!equalsObject(this.selectData,newAddress))
					this.updateAddress();
			} ,
			deep: true
		}
	} ,
	created() {
		if (!this.hasProvinces) {
			this.loadProvince = true;
			this.fetchProvinces().finally(()=>{
				this.loadProvince = false;
				this.updateAddress();
			})
		} else {
			this.updateAddress()
		}
	} ,

	methods: {
		...mapActions('data',['fetchProvinces']) ,
		updateAddress() {
			if (this.showPostalCode) {
				const postalCode = this.value && this.value.postal_code ? this.value.postal_code : undefined;
				Vue.set(this.selectData,'postal_code',postalCode)
			}
			if (this.value && !isStringEmpty(this.value.province)) {
				const provinceObj = this.provinces.find((provinceData) => provinceData.name === this.value.province)
				if (provinceObj) {
					this.selectData.province = this.value.province;
					this._fetchDistrict(provinceObj).then(()=> {
						if (!isStringEmpty(this.value.district)) {
							const districtObj = this.districts.find((districtData) => districtData.name === this.value.district)
							if (districtObj) {
								this.selectData.district = this.value.district;
								this._fetchSubdistrict(districtObj).then(()=> {
									if (!isStringEmpty(this.value.subdistrict)) {
										const subdistrictObj = this.subdistricts.find((subdistrictData) => subdistrictData.name === this.value.subdistrict)
										if(subdistrictObj) {
											this.selectData.subdistrict = this.value.subdistrict;
										}
									}
								})
							}
						}
					});
				}
			}
		} ,
		handleProvinceChange(value) {
			this.districts.splice(0,this.districts.length)
			this.subdistricts.splice(0,this.subdistricts.length)
			this.selectData.district = undefined;
			this.selectData.subdistrict = undefined;

			if (!isStringEmpty(this.selectData.province)) {
				const provinceObj = this.provinces.find((provinceData) => provinceData.name === this.selectData.province)
				this._fetchDistrict(provinceObj);
			}
			this.emitChange()
		},
		handleDistrictChange(value) {
			this.subdistricts.splice(0,this.subdistricts.length)
			this.selectData.subdistrict = undefined;

			if (!isStringEmpty(this.selectData.district)) {
				const districtObj = this.districts.find((districtData) => districtData.name === this.selectData.district)
				this._fetchSubdistrict(districtObj);
			}
			this.emitChange()
		} ,
		handleSubdistrictChange() {
			if (!isStringEmpty(this.selectData.subdistrict) && this.showPostalCode) {
				const subdistrictObj = this.subdistricts.find((subdistrictData) => subdistrictData.name === this.selectData.subdistrict);
				if (subdistrictObj) {
					this.selectData.postal_code = subdistrictObj.postal_code
				}
			}
			this.emitChange();
		} ,
		handlePostalChange(value) {
			this.emitChange();
		} ,
		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			);
		},

		emitChange : debounce(function(){
			this.$emit('input',{...this.value,...this.selectData})
		},200,{leading:false}) ,

		clearValidate() {
			this.hasErrorProvince = false
			this.hasErrorDistrict = false
			this.hasErrorSubdistrict = false
			this.hasErrorPostalCode = false
		} ,
		validate() {
			this.clearValidate()
			if (this.require) {
				if (isStringEmpty(this.selectData.province)) {
					this.hasErrorProvince = true
				}
				if (isStringEmpty(this.selectData.district)) {
					this.hasErrorDistrict = true
				}
				if (isStringEmpty(this.selectData.subdistrict)) {
					this.hasErrorSubdistrict = true
				}
				if (this.showPostalCode && isStringEmpty(this.selectData.postal_code)) {
					this.hasErrorPostalCode = true;
				}
			}

			if (this.requireProvince && isStringEmpty(this.selectData.province)) {
				this.hasErrorProvince = true
			}
			if (this.showPostalCode && this.requirePostalCode && isStringEmpty(this.selectData.postal_code)) {
				this.hasErrorPostalCode = true
			}
			return this.hasErrorProvince || this.hasErrorDistrict || this.hasErrorSubdistrict || this.hasErrorPostalCode
		} ,

		async _fetchDistrict(provinceObj) {
			if (!provinceObj || !provinceObj.id)
				return Promise.resolve()
			this.loadDistrict = true
			return new Promise((resolve,reject)=> {
				axios.get("/api/data/districts",{
					params : {id : provinceObj.id}
				}).then((response) => {
					this.districts = response.data.data.districts;
					this.loadDistrict = false
					resolve(provinceObj)
				}).catch((error) => {
					this.loadDistrict = false
					reject(error)
				})
			})
		} ,
		async _fetchSubdistrict(districtObj) {
			if (!districtObj || !districtObj.id)
				return Promise.resolve()

			this.loadSubdistrict = true
			return new Promise((resolve,reject)=> {
				axios.get("/api/data/subdistricts",{
					params : {id : districtObj.id}
				}).then((response) => {
					this.subdistricts = response.data.data.subdistricts;
					this.loadSubdistrict = false
					resolve(districtObj)
				}).catch((error) => {
					this.loadSubdistrict = false
					reject(error)
				})
			})
		}
	}


}
</script>

<style lang="less" scoped>
.province-select-container {
	display : flex;
	align-items: flex-start;
	flex-wrap: wrap;

	label {
		color : @info-color;
		display:block;
		line-height: 1.4em;
	}
	> div.province-block {
		width : 175px;
		margin : 0 4px 8px 0;

		&.required label::before {
			content: "* ";
		}


		.desktop &.postal-code-pane {
			flex-basis: 100%;
			input {
				width : 175px;
			}
		}
	}
}
</style>
