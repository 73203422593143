<i18n locale="th" lang="yaml" >
field.name : "ชื่อบริษัท"
field.name.placeholder: "ชื่อบริษัท ที่เรียกกันในระบบ"
field.abbr_name : "ตัวย่อ"
field.abbr_name.placeholder: "ชื่อย่อบริษัท"
field.abbr_name.help : "ตัวย่อบริษัท ใช้ประกอบในการออกหมายเลขใบต่าง ๆ เช่น SR-TJ-2020001 - Service ของเกษตรทันใจ หมายเลข xxx"
field.company_type : "ประเภทบริษัท"
field.company_type.help : "สำหรับบริษัทประเภทอื่น ติดต่อ Admin"
field.juristic_no: "เลขทะเบียนนิติบุคคล"
field.juristic_no.placeholder: "ระบุเลขจดทะเบียนนิติบุคคล"
field.billing_name : "ชื่อจดทะเบียน"
field.billing_name.placeholder: "ระบุชื่อบริษัทที่ใช้ในการ ออกใบเสร็จ"
field.billing_address: "ที่อยู่ในใบเสร็จ"
field.billing_address.placeholder: "ระบุที่อยุ่ที่ใช้ในการ ออกใบเสร็จ หรือ เอกสาร"
field.address: "ที่อยู่"
field.address.placeholder: "ระบุที่อยู่จริง ของบริษัท"
field.email : "Email"
field.email.placeholder: "ระบุ Email ที่ใช้ในการติดต่อ และ ส่งข้อมูล"
field.phone : "เบอร์โทรศัพท์"
field.phone.placeholder: "ระบุเบอร์โทรศัพท์มือถือ เพื่อใช้ในการติดต่อ"
field.image.help : "รูปบริษัท ควรมีขนาด อย่างน้อย 256x256 px"
field.company_type.required : "กรุณาระบุ ประเภทศูนย์บริการ"
</i18n>

<template>
	<a-form
		layout="horizontal"
		:label-col="labelCol"
		:wrapper-col="wrapperCol"
		:form="formObj"
		@submit.prevent="handleSubmit">

		<a-form-item :help="$t('field.image.help')"
			style="text-align:center;margin-bottom:16px;"
			:label-col="{ span: 0 }"
			:wrapper-col="{ span: 24 }">
				<ImageUploader
					:current-image-url="company.image_url"
					:max-width="512"
					:max-height="512"
					border-type="round"
					size="large"
					@error="handleFileUploadError"
					@change="hadleFileUploadChange">
					<a-icon type="shop" />
				</ImageUploader>
		</a-form-item>

		<a-form-item :label="$t('field.name')">
			<a-input
				v-decorator="[
					'name' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: $tt('validate.required','field.name') },
					] },
				]"
				type="text"
				:placeholder="$t('field.name.placeholder')">
				<a-icon slot="prefix" type="shop" />
			</a-input>
		</a-form-item>
		<a-form-item :label="$t('field.abbr_name')" :extra="$t('field.abbr_name.help')">
			<a-input
				v-decorator="[
					'abbr_name' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: $tt('validate.required','field.abbr_name') },
					] },
				]"
				type="text"
				:max-length="4"
				:placeholder="$t('field.abbr_name.placeholder')">
			</a-input>
		</a-form-item>
		<a-form-item
			v-if="mode === 'create'" :label="$t('field.company_type')"
			:extra="$t('field.company_type.help')">
			<CompanyTypeSelect
				ref="companySelect"
				v-decorator="[
					'company_type' ,
					{
						rules: [
							{ required: true,message: $tt('validate.required','field.company_type.required') },
						],
					},
				]"
				:company-types="companyTypes"
				:is-company-type="true"
				allow-clear/>
		</a-form-item>
		<a-form-item
			v-else
			:label="$t('field.company_type')">
			<span class="ant-form-text">
				{{$tenum('company_type',`${company.company_type}`)}}
			</span>
		</a-form-item>

		<a-form-item :label="$t('field.address')">
			<a-textarea
				v-decorator="['address']"
				:auto-size="{ minRows: 2,maxRows: 5 }"
				:placeholder="$t('field.address.placeholder')">
			</a-textarea>
		</a-form-item>

		<a-form-item v-bind="wrapperOnlyLayout">
			<ProvinceSelect
				ref="provinceSelect"
				v-model="address"
				:require-postal-code="true"
				:require-province="true"
				:show-postal-code="true"/>
		</a-form-item>

		<hr />
		<a-form-item :label="$t('field.phone')">
			<a-input
				v-decorator="['contact_phone']"
				type="tel"
				:max-length="20"
				:placeholder="$t('field.phone.placeholder')">
				<a-icon slot="prefix" type="phone" />
			</a-input>
		</a-form-item>
		<a-form-item :label="$t('field.email')">
			<a-input
				v-decorator="[
					'contact_email' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: $tt('validate.required','field.email') },
					] },
				]"
				type="email"
				:placeholder="$t('field.email.placeholder')">
				<a-icon slot="prefix" type="mail" />
			</a-input>
		</a-form-item>


		<hr />

		<a-form-item :label="$t('field.juristic_no')">
			<a-input
				v-decorator="['juristic_no']"
				type="text"
				:placeholder="$t('field.juristic_no.placeholder')">
			</a-input>
		</a-form-item>
		<a-form-item :label="$t('field.billing_name')">
			<a-input
				v-decorator="[
					'billing_name' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: $tt('validate.required','field.billing_name') },
					] },
					]"
				type="text"
				:placeholder="$t('field.billing_name.placeholder')">
			</a-input>
		</a-form-item>
		<a-form-item :label="$t('field.billing_address')">
			<a-textarea
				v-decorator="[
					'billing_address' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: $tt('validate.required','field.billing_address') },
					] },
				]"
				:auto-size="{ minRows: 2,maxRows: 5 }"
				:placeholder="$t('field.billing_address.placeholder')">
			</a-textarea>
		</a-form-item>


		<a-form-item v-bind="wrapperOnlyLayout">
			<slot name="submitSlot" :formLoading="loading">
				<a-button
					:loading="loading"
					type="info"
					html-type="submit"
					class="btn-submit"
					size="large">
					<a-icon type="save" /> {{$t('common.save')}}
				</a-button>
			</slot>
		</a-form-item>
	</a-form>

</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import ImageUploader from '@components/input/ImageUploader.vue'
import ProvinceSelect from '@components/input/ProvinceSelect.vue'
import {replaceProperty} from "@utils/objectUtil.js"
import {updateFieldsValue} from "@utils/formUtil.js"
import ApiError from '@utils/errors/ApiError'
import CompanyTypeSelect from './CompanyTypeSelect.vue'

export default {
	components : {
		ImageUploader,ProvinceSelect ,
		CompanyTypeSelect
	} ,
	mixins: [HasAntdFormMixin],
	props : {
		mode : {
			type : String ,
			default : 'update'
		} ,
		loading : {
			type : Boolean ,
			default : false,
		} ,
		companyTypes : {
			type: Array ,
			default : () => []
		} ,
		allTypes : {
			type : Array ,
			default : () => []
		}
	} ,
	data() {
		return {
			imageUploadData : {} ,
			company : {},
			address : {
				province : undefined,
				district : undefined,
				subdistrict: undefined,
				postal_code: undefined,
			},
		}
	} ,
	methods : {
		handleFileUploadError(error) {
			this.$message.error(ApiError.getDisplayErrorMessage(this,error));
		} ,
		hadleFileUploadChange(value) {
			this.imageUploadData = value;
		} ,
		updateCompanyForm(company) {
			this.company = {...company};
			this.address = replaceProperty(this.address,this.company)
			updateFieldsValue(this.formObj,company)
		} ,
		formErrors(error) {
			this.formSubmitErrors(error)
		} ,
		handleSubmit() {
			this.formObj.validateFields((err,values) => {
				if (!this.$refs.provinceSelect.validate() && !err) {
					this.allTypes.forEach((type) => {
						if (values.company_type === type.name)
							values.company_type = type.id
					})
					const formData = {
						...values ,
						...this.address,
						'image_file_base64' : this.imageUploadData ,
					};
					this.$emit('submit',formData)
				} else {
					this.displayValidateErrorMessage()
				}
			});

		}
	}
}
</script>
