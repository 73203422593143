var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"layout":"horizontal","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.formObj},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{staticStyle:{"text-align":"center","margin-bottom":"16px"},attrs:{"help":_vm.$t('field.image.help'),"label-col":{ span: 0 },"wrapper-col":{ span: 24 }}},[_c('ImageUploader',{attrs:{"current-image-url":_vm.company.image_url,"max-width":512,"max-height":512,"border-type":"round","size":"large"},on:{"error":_vm.handleFileUploadError,"change":_vm.hadleFileUploadChange}},[_c('a-icon',{attrs:{"type":"shop"}})],1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
				'name' ,
				{ rules: [
					{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','field.name') },
				] },
			]),expression:"[\n\t\t\t\t'name' ,\n\t\t\t\t{ rules: [\n\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','field.name') },\n\t\t\t\t] },\n\t\t\t]"}],attrs:{"type":"text","placeholder":_vm.$t('field.name.placeholder')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"shop"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.abbr_name'),"extra":_vm.$t('field.abbr_name.help')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
				'abbr_name' ,
				{ rules: [
					{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','field.abbr_name') },
				] },
			]),expression:"[\n\t\t\t\t'abbr_name' ,\n\t\t\t\t{ rules: [\n\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','field.abbr_name') },\n\t\t\t\t] },\n\t\t\t]"}],attrs:{"type":"text","max-length":4,"placeholder":_vm.$t('field.abbr_name.placeholder')}})],1),_vm._v(" "),(_vm.mode === 'create')?_c('a-form-item',{attrs:{"label":_vm.$t('field.company_type'),"extra":_vm.$t('field.company_type.help')}},[_c('CompanyTypeSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
				'company_type' ,
				{
					rules: [
						{ required: true,message: _vm.$tt('validate.required','field.company_type.required') },
					],
				},
			]),expression:"[\n\t\t\t\t'company_type' ,\n\t\t\t\t{\n\t\t\t\t\trules: [\n\t\t\t\t\t\t{ required: true,message: $tt('validate.required','field.company_type.required') },\n\t\t\t\t\t],\n\t\t\t\t},\n\t\t\t]"}],ref:"companySelect",attrs:{"company-types":_vm.companyTypes,"is-company-type":true,"allow-clear":""}})],1):_c('a-form-item',{attrs:{"label":_vm.$t('field.company_type')}},[_c('span',{staticClass:"ant-form-text"},[_vm._v("\n\t\t\t"+_vm._s(_vm.$tenum('company_type',`${_vm.company.company_type}`))+"\n\t\t")])]),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.address')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address']),expression:"['address']"}],attrs:{"auto-size":{ minRows: 2,maxRows: 5 },"placeholder":_vm.$t('field.address.placeholder')}})],1),_vm._v(" "),_c('a-form-item',_vm._b({},'a-form-item',_vm.wrapperOnlyLayout,false),[_c('ProvinceSelect',{ref:"provinceSelect",attrs:{"require-postal-code":true,"require-province":true,"show-postal-code":true},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.phone')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['contact_phone']),expression:"['contact_phone']"}],attrs:{"type":"tel","max-length":20,"placeholder":_vm.$t('field.phone.placeholder')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"phone"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.email')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
				'contact_email' ,
				{ rules: [
					{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','field.email') },
				] },
			]),expression:"[\n\t\t\t\t'contact_email' ,\n\t\t\t\t{ rules: [\n\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','field.email') },\n\t\t\t\t] },\n\t\t\t]"}],attrs:{"type":"email","placeholder":_vm.$t('field.email.placeholder')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.juristic_no')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['juristic_no']),expression:"['juristic_no']"}],attrs:{"type":"text","placeholder":_vm.$t('field.juristic_no.placeholder')}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.billing_name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
				'billing_name' ,
				{ rules: [
					{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','field.billing_name') },
				] },
				]),expression:"[\n\t\t\t\t'billing_name' ,\n\t\t\t\t{ rules: [\n\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','field.billing_name') },\n\t\t\t\t] },\n\t\t\t\t]"}],attrs:{"type":"text","placeholder":_vm.$t('field.billing_name.placeholder')}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.billing_address')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
				'billing_address' ,
				{ rules: [
					{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','field.billing_address') },
				] },
			]),expression:"[\n\t\t\t\t'billing_address' ,\n\t\t\t\t{ rules: [\n\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','field.billing_address') },\n\t\t\t\t] },\n\t\t\t]"}],attrs:{"auto-size":{ minRows: 2,maxRows: 5 },"placeholder":_vm.$t('field.billing_address.placeholder')}})],1),_vm._v(" "),_c('a-form-item',_vm._b({},'a-form-item',_vm.wrapperOnlyLayout,false),[_vm._t("submitSlot",function(){return [_c('a-button',{staticClass:"btn-submit",attrs:{"loading":_vm.loading,"type":"info","html-type":"submit","size":"large"}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v(" "+_vm._s(_vm.$t('common.save'))+"\n\t\t\t")],1)]},{"formLoading":_vm.loading})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }